
import React, { useState } from 'react';
import { Card, Form } from 'semantic-ui-react';

import { TxButton } from '../substrate-lib/components';

export default function RegisterDestinationForm (props) {
  const { accountPair } = props;
  const [status, setStatus] = useState([]);
  const [params, setParams] = useState({ id: null, country: null });

  const onChange = (_, data) => {
    const newParams = { ...params };
    if (data.state === 'id') {
      newParams.id = (data.value.length === 0 ? null : data.value);
    }
    if (data.state === 'code') {
      newParams.code = (data.value.length === 0 ? null : data.value);
    }
    if (!isNaN(newParams.code)) {
      newParams.code = null;
    }
    setParams(newParams);
  };

  return <Card fluid color='blue'>
    <Card.Content style={{ flexGrow: 0 }} header='Register destination' />
    <Card.Content>
      <Card.Description>
        <Form>
          <Form.Input
            fluid required
            label='Account ID'
            name='accId'
            state='id'
            onChange={onChange}
          />
          <Form.Input
            fluid required
            label='Country code'
            name='code'
            state='code'
            onChange={onChange}
          />
          <Form.Field>
            <TxButton
              accountPair={accountPair}
              label='Register'
              type='SUDO-TX'
              style={{ display: 'block', margin: 'auto' }}
              setStatus={setStatus}
              attrs={{
                palletRpc: 'certificateOfOriginV1',
                callable: 'addDestination',
                inputParams: [params.id, params.code],
                paramFields: [true, true]
              }}
            />
          </Form.Field>
          <div style={{ overflowWrap: 'break-word' }}>{status}</div>
        </Form>
      </Card.Description>
    </Card.Content>
  </Card>;
}
