import { Header, Grid } from 'semantic-ui-react';

import DestinationsList from './DestinationsList';
import RegisterDestination from './RegisterDestination';

export default function Main (props) {
  const { accountPair } = props;

  return <Grid.Column width={8} >

        <Header as='h2' textAlign ="center">Destinations</Header>
        <RegisterDestination accountPair={accountPair}/>
        <DestinationsList accountPair={accountPair}/>

  </Grid.Column>;
}
