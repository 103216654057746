import { Header, Grid } from 'semantic-ui-react';
import DocumentsList from './DocumentsList';

export default function Main () {
  return <Grid.Column width={8}>

    <Header as='h2' textAlign="center">Documents</Header>
    <DocumentsList />

  </Grid.Column>;
}
