import React, { useState, createRef } from 'react';
import { Tab, Container, Dimmer, Loader, Grid, Sticky, Message } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import { SubstrateContextProvider, useSubstrate } from './substrate-lib';
import { DeveloperConsole } from './substrate-lib/components';

import AccountSelector from './AccountSelector';
import Accounts from './Accounts';
import BlockNumber from './BlockNumber';
import Destinations from './destinations/Destinations';
import Documents from './documents/Documents';
import Events from './Events';
import Interactor from './Interactor';
import Issuers from './issuers/Issuers';
import Metadata from './Metadata';
import NodeInfo from './NodeInfo';
import Upgrade from './Upgrade';

function Main () {
  const [accountAddress, setAccountAddress] = useState(null);
  const { apiState, keyring, keyringState, apiError } = useSubstrate();
  const accountPair =
    accountAddress &&
    keyringState === 'READY' &&
    keyring.getPair(accountAddress);

  const loader = text =>
    <Dimmer active>
      <Loader size='small'>{text}</Loader>
    </Dimmer>;

  const message = err =>
    <Grid centered columns={2} padded>
      <Grid.Column>
        <Message negative compact floating
          header='Error Connecting to Substrate'
          content={`${JSON.stringify(err, null, 4)}`}
        />
      </Grid.Column>
    </Grid>;

  if (apiState === 'ERROR') return message(apiError);
  else if (apiState !== 'READY') return loader('Connecting to Substrate');

  if (keyringState !== 'READY') {
    return loader('Loading accounts (please review any extension\'s authorization)');
  }
  const panes = [
    { menuItem: 'Issuers', render: () => <Tab.Pane style={{ minWidth: '70em', marginLeft: '-8em' }}><Issuers accountPair={accountPair} /></Tab.Pane> },
    { menuItem: 'Destinations', render: () => <Tab.Pane style={{ minWidth: '70em', marginLeft: '-8em' }}><Destinations accountPair={accountPair} /></Tab.Pane> },
    { menuItem: 'Documents', render: () => <Tab.Pane style={{ minWidth: '70em', marginLeft: '-8em' }}><Documents /></Tab.Pane> },
    { menuItem: 'Upgrade', render: () => <Tab.Pane><Upgrade accountPair={accountPair} /></Tab.Pane> },
    { menuItem: 'Interactor', render: () => <Tab.Pane><Interactor accountPair={accountPair} /></Tab.Pane> }
  ];

  const contextRef = createRef();

  return (
    <div ref={contextRef}>
      <Sticky context={contextRef}>
        <AccountSelector setAccountAddress={setAccountAddress} />
      </Sticky>
      <Container>
        <Grid stackable columns='equal'>
          <Grid.Row stretched>
            <NodeInfo />
            <Metadata />
            <BlockNumber />
            <BlockNumber finalized />
          </Grid.Row>
          <Grid.Row>
            <Accounts />
            <Events />
          </Grid.Row>
          <Grid.Row stretched>
            <Tab menu={{ fluid: false, vertical: true, tabular: true }} panes={panes} />

          </Grid.Row>
        </Grid>
      </Container>
      <DeveloperConsole />
    </div>
  );
}

export default function App () {
  return (
    <SubstrateContextProvider>
      <Main />
    </SubstrateContextProvider>
  );
}
