const utils = {
  paramConversion: {
    num: [
      'Compact<Balance>',
      'BalanceOf',
      'u8', 'u16', 'u32', 'u64', 'u128',
      'i8', 'i16', 'i32', 'i64', 'i128'
    ]
  },
  arrayContainsNaN (array) {
    return array.some(x =>
      (Array.isArray(x) && this.arrayContainsNaN(x)) ||
      (typeof x === 'number' && Number.isNaN(x))
    );
  }
};

export default utils;
