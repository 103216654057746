import React, { useEffect, useState } from 'react';
import { Message, Table } from 'semantic-ui-react';
import { useSubstrate } from '../substrate-lib';
import { TxButton } from '../substrate-lib/components';

export default function Main (props) {
  const { api } = useSubstrate();
  const [issuers, setIssuers] = useState([]);
  const [status, setStatus] = useState([]);
  const { keyring } = useSubstrate();
  const { accountPair } = props;

  useEffect(() => {
    let unsub = null;

    const getIssuers = async () => {
      const accounts = keyring.getPairs();

      unsub = await api.query.certificateOfOriginV1.issuers.keys(keys => {
        const issuers = keys.map((key) => {
          const addr = key.toHuman()[0];
          return {
            key: key,
            address: addr,
            name: accounts.find(account => account.address === addr)?.meta.name
          };
        });

        setIssuers(issuers);
      });
    };

    getIssuers();

    return () => unsub && unsub();
  });

  if (!issuers || issuers.length === 0) {
    return <Message warning>
      <Message.Header>No issuers existed in the chain.</Message.Header>
    </Message>;
  }

  return <Table color='blue'>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={{ minWidth: '10em' }} >Issuer</Table.HeaderCell>
        <Table.HeaderCell style={{ minWidth: '35em' }}>Issuer address</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>{issuers.map(issuer => {
      const address = issuer.address;
      const name = issuer.name;

      return <Table.Row key={address}>
        <Table.Cell width={4}>
          <span style={{ display: 'inline-block', minWidth: '18em' }}>
            {name}
          </span>
        </Table.Cell>
        <Table.Cell width={6} textAlign='left' style={{ minWidth: '30em' }}>
          <span style={{ display: 'inline-block', minWidth: '30em' }}>
            {address}
          </span>
          <TxButton
            accountPair={accountPair}
            label='Delete'
            type='SUDO-TX'
            style={{ display: 'inline-block', marginLeft: '3em', align: 'center' }}
            acceptForm='true'
            setStatus={setStatus}
            attrs={{
              palletRpc: 'certificateOfOriginV1',
              callable: 'removeIssuer',
              inputParams: [address],
              paramFields: [true]
            }}
          >
          </TxButton>
        </Table.Cell>
      </Table.Row>;
    })}</Table.Body>
    <Table.Footer>
      <Table.Row>
        <Table.Cell>
          <div style={{ overflowWrap: 'break-word', maxWidth: '20em' }}>{status}</div>
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  </Table>;
}
