import React, { useEffect, useState } from 'react';
import { Form, Grid, Message } from 'semantic-ui-react';
import { useSubstrate } from '../substrate-lib';
import PaginationDocsTable from './PaginationDocumentsTable';

export default function Main () {
  const { api } = useSubstrate();
  const [loadedDocuments, setLoadedDocuments] = useState([]);
  const [currentPageSize, setCurrentPageSize] = useState(5);
  const [currentStartIndex, setCurrentStartIndex] = useState(0);
  const [view, setView] = useState([]);
  const [allLoaded, setAllLoaded] = useState(false);
  const [filter, setFilter] = useState(null);

  const onChange = (_, data) => {
    const newFilter = data.value.length === 0 ? null : data.value;
    setFilter(newFilter);
  };

  const onPageChange = (needNext, needPrev, needRowsChange, rowsPerPage) => {
    if (needNext) {
      setCurrentStartIndex(curr => curr + currentPageSize);
    } else if (needPrev) {
      setCurrentStartIndex(curr => curr - currentPageSize);
    } else if (needRowsChange) {
      setCurrentPageSize(rowsPerPage);
      setCurrentStartIndex(0);
    }
  };

  useEffect(() => {
    const loadSingle = async (documentId) => {
      const option = await api.query.certificateOfOriginV1.documents(documentId);
      if (option.isNone) {
        return null;
      }

      return option.value;
    };

    const loadMultiple = async (startDocumentId, size) => {
      const pageOpts = {
        args: [],
        pageSize: size,
        startKey: startDocumentId
      };

      const entries = await api.query.certificateOfOriginV1.documents.entriesPaged(pageOpts);
      return entries.map(([documentId, documentInfo]) => ({
        documentId: documentId,
        info: documentInfo.value
      }));
    };

    const loadView = async () => {
      if (filter != null) {
        const singleDocument = await loadSingle(filter);
        setView([singleDocument]
          .filter(info => info)
          .map(info => ({ documentId: filter, info: info })));
        return;
      }

      const expectedLength = currentStartIndex + currentPageSize;
      if (!allLoaded && loadedDocuments.length < expectedLength) {
        const startDocumentId = loadedDocuments[loadedDocuments.length - 1]?.documentId;
        const loadCount = expectedLength - loadedDocuments.length + 1;
        const newDocuments = await loadMultiple(startDocumentId, loadCount);
        if (newDocuments.length < loadCount) {
          setAllLoaded(true);
        }
        if (loadedDocuments.length === 0) {
          setLoadedDocuments(newDocuments);
        } else if (newDocuments.length > 0) {
          setLoadedDocuments(curr => curr.concat(newDocuments));
        }
        console.log({ currentStartIndex, expectedLength, loadedDocuments: loadedDocuments.map(x => x.documentId.toHuman()[0]), newDocuments: newDocuments.map(x => x.documentId.toHuman()[0]) });
      } else {
        setView(loadedDocuments
          .slice(currentStartIndex, expectedLength)
          .map(doc => ({ documentId: doc.documentId.toHuman(), info: doc.info })));
      }
    };

    loadView();
  }, [currentStartIndex, loadedDocuments, allLoaded, currentPageSize, filter, api]);

  if (!loadedDocuments || loadedDocuments.length === 0) {
    return <Grid.Column>
      <Message warning>
        <Message.Header>No documents existed in the chain.</Message.Header>
      </Message>
    </Grid.Column>;
  }

  return (
    <Grid>
      <Grid.Column>
        <Form>
          <Form.Input
            label='Document ID'
            name='DocumentId'
            state='id'
            onChange={onChange}
            style={{ marginBottom: '2em' }}
          />
        </Form>
        <PaginationDocsTable
          onPageChange={onPageChange}
          documents={view}
          totalCount={loadedDocuments.length}
        />
      </Grid.Column>
    </Grid>
  );
}
