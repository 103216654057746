
import { Table, Grid, Button } from 'semantic-ui-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSubstrate } from './substrate-lib';

export default function Main (props) {
  const { keyring } = useSubstrate();
  const accounts = keyring.getPairs();
  return (
    <Grid.Column>
        <h1 style ={{ textAlign: 'center' }}>Accounts</h1>
      <Table celled striped size='small'>
        <Table.Body>
          <Table.Row>
            <Table.Cell width={3} textAlign="right">
              <strong>Name</strong>
            </Table.Cell>
            <Table.Cell width={10} >
              <strong>Address</strong>
            </Table.Cell>
          </Table.Row>
          {accounts.map(account =>
            <Table.Row key={account.address}>
              <Table.Cell width={3} textAlign="right">{account.meta.name}</Table.Cell>
              <Table.Cell width={10}>
                <span style={{ display: 'inline-block', minWidth: '30em' }}>
                  {account.address}
                </span>
                <CopyToClipboard text={account.address}>
                  <Button
                    basic
                    circular
                    compact
                    size='mini'
                    color='blue'
                    icon='copy outline'
                  />
                </CopyToClipboard>
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </Grid.Column>
  );
}
