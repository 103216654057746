import React, { useEffect, useState } from 'react';
import { Message, Table } from 'semantic-ui-react';
import { useSubstrate } from '../substrate-lib';
import { TxButton } from '../substrate-lib/components';

export default function Main (props) {
  const { api, keyring } = useSubstrate();
  const [destinations, setDestinations] = useState([]);
  const [status, setStatus] = useState([]);
  const { accountPair } = props;

  useEffect(() => {
    let unsub = null;

    const getDestinations = async () => {
      const accounts = keyring.getPairs();

      unsub = await api.query.certificateOfOriginV1.destinations.entries(entries => {
        const destinations = entries.map(([key, countryCode]) => {
          const addr = key.toHuman()[0];
          return {
            key: key,
            address: addr,
            countryCode: countryCode.toHuman(),
            name: accounts.find(account => account.address === addr)?.meta.name
          };
        });

        setDestinations(destinations);
      });
    };

    getDestinations();

    return () => unsub && unsub();
  });

  if (!destinations || destinations.length === 0) {
    return <Message warning>
      <Message.Header>No destinations existed in the chain.</Message.Header>
    </Message>;
  }

  return <Table color='blue'>
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell style={{ minWidth: '5em' }}>Destination</Table.HeaderCell>
        <Table.HeaderCell style={{ minWidth: '20em' }}>Destination Address</Table.HeaderCell>
        <Table.HeaderCell style={{ minWidth: '10em' }}>Country</Table.HeaderCell>
      </Table.Row>
    </Table.Header>

    <Table.Body>{destinations.map(destination => {
      const address = destination.address;
      const countryCode = destination.countryCode;
      const name = destination.name;

      return <Table.Row key={address}>
        <Table.Cell width={4}>
          <span style={{ display: 'inline-block', minWidth: '10em' }}>
            {name}
          </span>
        </Table.Cell>
        <Table.Cell width={4}>
          <span style={{ display: 'inline-block', minWidth: '20em' }}>
            {address}
          </span>
        </Table.Cell>
        <Table.Cell width={6} textAlign='bottom' style={{ minWidth: '10em' }}>
          <span style={{ display: 'inline-block', minWidth: '5em' }}>
            {countryCode}
          </span>
          <TxButton
            accountPair={accountPair}
            label='Delete'
            type='SUDO-TX'
            style={{ display: 'inline-block', marginLeft: '3em', align: 'center' }}
            setStatus={setStatus}
            attrs={{
              palletRpc: 'certificateOfOriginV1',
              callable: 'removeDestination',
              inputParams: [address],
              paramFields: [true]
            }}
          >
          </TxButton>
        </Table.Cell>
      </Table.Row>;
    })}</Table.Body>
    <Table.Footer>
      <Table.Row>
        <Table.Cell>
          <div style={{ overflowWrap: 'break-word', maxWidth: '20em' }}>{status}</div>
        </Table.Cell>
      </Table.Row>
    </Table.Footer>
  </Table>;
}
