
import { Header, Grid } from 'semantic-ui-react';

import IssuersList from './IssuersList';
import RegisterIssuer from './RegisterIssuer';

export default function Main (props) {
  const { accountPair } = props;

  return <Grid.Column width={8}>

        <Header as='h2' textAlign ="center">Issuers</Header>
        <RegisterIssuer accountPair={accountPair}/>
        <IssuersList accountPair={accountPair}/>

  </Grid.Column>;
}
